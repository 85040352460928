// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    name: 'Walden', 
    assetBaseUrl:'https://firebasestorage.googleapis.com/v0/b/walden-labs-prod-277621.appspot.com/o/chatbots%2Fstudent%2Fassets%2F',
    authService: 'microsoft.com',
    tenantId:'7e53ec4a-d325-4228-9e0e-a55a6b8892d5',
    oktaAuthService:'oidc.okta',
    clientId:'0oa2342v06zO3TrHA0h8',
    issuer:'https://atge.oktapreview.com',
    //redirectUri:'http://localhost:4200/login/callback',
    redirectUri:'https://genai-qa.waldenu.edu/login/callback',
    oktaSignoutRedirectUri: 'https://genai-qa.waldenu.edu',
    studentAzureChatBotServiceFlag:true,
    studentGCPChatBotServiceFlag:true,
    studentAvaamoChatBotServiceFlag:true,
    chatbotName:'Charlotte',


    studentAsStaffConfig: {
      chatUrl: 'https://charlotte-microapp-qa.web.app/assets/js/chatbot.js',
      channelId: 'c22c5904-1fca-4db3-84f2-925ae85d6506',
      botApiUrl: "https://bis-api-dot-app-studentportal-qa.ue.r.appspot.com/staffassistantqa/token",
      sasCookies : "https://genai-admin-qa.waldenu.edu/create_user_delegation_sas_container/",
      sasToken: "https://genai-admin-qa.waldenu.edu/create_user_delegation_sas_container/",
      refreshChat: "https://genai-admin-qa.waldenu.edu/clear_history_api/",
      searchApiRequest: "https://genai-admin-qa.waldenu.edu/search_api_request/",
      chatBotId: "",
      convoAction: "https://genai-admin-qa.waldenu.edu/convo_action/",
      convoFeedback: "https://genai-admin-qa.waldenu.edu/convo_feedback/",
      getChatbotId: "https://genai-admin-qa.waldenu.edu/get_chatbot_id/",
      citationPayloadLink : "https://genai-admin-qa.waldenu.edu/citation_category_group_api/",
      chatHistory : "https://genai-admin-qa.waldenu.edu/get_chat_history_api/",
      cachedResponseConstant: 'Saved Student Response',
    },
    enrollmentConfig: {
      sasCookies : "https://genai-admin-qa.waldenu.edu/create_user_delegation_sas_container/",
      refreshChat : "https://genai-admin-qa.waldenu.edu/clear_history_api/",
      searchApiRequest : "https://genai-admin-qa.waldenu.edu/search_api_request/",
      citationPDF : "https://genaicopilotmmpqablob.blob.core.windows.net/enrollment-copilot-qa",
      citationPayloadLink : "https://genai-admin-qa.waldenu.edu/citation_category_group_api/",
      convoActionAPI : "https://genai-admin-qa.waldenu.edu/convo_action/",
      convoFeedbackAPI: "https://genai-admin-qa.waldenu.edu/convo_feedback/",
      chatHistory : "https://genai-admin-qa.waldenu.edu/get_chat_history_api/",
			chatBotId: "",
      getChatbotId: "https://genai-admin-qa.waldenu.edu/get_chatbot_id/",
      salesforceConfigUrl:"https://laureateone--fsb.sandbox.lightning.force.com",
      acknowledgementAPI: "https://genai-admin-qa.waldenu.edu/acknowledgement_api/",
      cachedResponseConstant: 'Saved Enrollment Response'
    },
    
      Question1:"What support services are available for Walden students?",
      Question2:"How is Walden's faculty different from other online universities?",
      Question3:"Can you tell me about Walden's accreditation?"
};

(window as any).env = environment;
